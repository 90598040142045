import './index.css';

function DatesContainer(props) {
    return (
        <div id="DatesContainer">
            <h4>{props.date}</h4>
        </div>
    );
}

export default DatesContainer;