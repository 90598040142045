
import AboutBottomLeftQuadrant from './AboutBottomLeftQuadrant';
import AboutTopLeftQuadrant from './AboutTopLeftQuadrant';
import './index.css';

function AboutLeftContainer() {
    return (
        <div id='AboutLeftContainer'>
            <AboutTopLeftQuadrant />
            {/* <AboutBottomLeftQuadrant /> */}
        </div>
    );
}

export default AboutLeftContainer;