import './index.css';

function ExperienceTitleText() {
    return (
        <div className="ExperienceTitleText">
            <h1>My Experience</h1>
        </div>
    );
}

export default ExperienceTitleText;