import './index.css';

function ContactTitleContainer() {
    return (
        <div id="ContactTitleContainer">
            <h2>Contact Me!</h2>
        </div>
    );
}

export default ContactTitleContainer;