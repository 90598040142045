import './index.css';

function ProjectsTitleText() {
    return (
        <div id="ProjectsTitleText">
            <h1>Highlighted Projects</h1>
        </div>
    );
}

export default ProjectsTitleText;