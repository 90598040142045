
import MyGallery from './MyGallery';
import './index.css';

function AboutPhotoContainer() {
    return (
        <div id='AboutPhotoContainer'>
            <MyGallery />
        </div>
    );
}

export default AboutPhotoContainer;