import './index.css';

function CopyrightContainer() {
    return (
        <div id="CopyrightContainer">
            <p>&copy; Jacob Moore 2023</p>
        </div>
    );
}

export default CopyrightContainer;