
import AboutParagraphContainer from './AboutParagraphContainer';
import './index.css';

function AboutRightContainer() {
    return (
        <div id='AboutRightContainer'>
            <AboutParagraphContainer />
        </div>
    );
}

export default AboutRightContainer;