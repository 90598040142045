import './index.css';

function RoleContainer(props) {
    return (
        <div id="RoleContainer">
            <h4>{props.role}</h4>
        </div>
    );
}

export default RoleContainer;