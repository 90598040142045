import './index.css';
import '@passageidentity/passage-elements/passage-auth';

function PassageSuccess() {
    return (
        <div id="PassageSuccess">
            <p>Way to go!</p>
        </div>
    );
}

export default PassageSuccess;